import { defineAsyncComponent } from "vue";
import { SetupCalendar } from "v-calendar";
import { plugin as VueTippy } from "vue-tippy";
import { START_LOCATION } from "vue-router";

import { useNotyf } from "/@src/lib/composable/useNotyf";
import { useUserSession } from "/@src/lib/stores/userSession";
import background from "/@src/lib/directives/background";
import tooltip from "/@src/lib/directives/tooltip";
//import hideOnHidden from "/@src/lib/directives/hideOnHidden";

import type { JarveatAppContext } from "./app";

// Lazy load aditional components
export async function registerGlobalComponents({ app }: JarveatAppContext) {
    app.use(SetupCalendar, {});
    app.use(VueTippy, {
        component: "Tippy",
        defaultProps: {
            theme: "light",
        },
    });

    app.component(
        // eslint-disable-next-line vue/multi-word-component-names
        "Multiselect",
        defineAsyncComponent({
            loader: () => import("@vueform/multiselect").then((mod) => mod.default),
            delay: 0,
            suspensible: false,
        })
    );
    app.component(
        // eslint-disable-next-line vue/multi-word-component-names
        "Slider",
        defineAsyncComponent({
            loader: () => import("@vueform/slider").then((mod) => mod.default),
            delay: 0,
            suspensible: false,
        })
    );
    app.component(
        "VCalendar",
        defineAsyncComponent({
            loader: () => import("v-calendar").then((mod) => mod.Calendar),
            delay: 0,
            suspensible: false,
        })
    );
    app.component(
        "VDatePicker",
        defineAsyncComponent({
            loader: () => import("v-calendar").then((mod) => mod.DatePicker),
            delay: 0,
            suspensible: false,
        })
    );

    app.directive("background", background);
    app.directive("tooltip", tooltip);
}

/**
 * Here we are setting up two router navigation guards
 *
 * We can add meta to pages either by declaring them manualy in the
 * routes declaration (see /@src/router.ts)
 * or by adding a <route> tag into .vue files (see /@src/pages/sidebar/dashboards.ts)
 *
 * <route lang="yaml">
 * meta:
 *   requiresAuth: true
 * </route>
 *
 * <script setup lang="ts">
 *  // TS script
 * </script>
 *
 * <template>
 *  // HTML content
 * </template>
 */
export function registerRouterNavigationGuards({ router }: JarveatAppContext) {
    //const { t } = useI18n();
    router.beforeEach(async (to, from) => {
        const userSession = useUserSession();
        const notyf = useNotyf();

        const isLoggedIn = await userSession.isLoggedIn();

        if (from === START_LOCATION && isLoggedIn) {
            // 1. If the name is not set, it means we are navigating to the first page
            // and we are logged in, so we should check user information from the server
            try {
                if (to.meta.roles && !(await userSession.hasRole(to.meta.roles))) {
                    return {
                        // Will follow the redirection set in /@src/pages/auth/index.vue
                        name: "auth",
                        // save the location we were at to come back later
                        query: { redirect: to.fullPath },
                    };
                }
            } catch (err) {
                console.error(err);
                // delete stored token if it fails
                userSession.logoutUser();

                if (to.meta.requiresAuth) {
                    // redirect the user somewhere
                    return {
                        // Will follow the redirection set in /@src/pages/auth/index.vue
                        name: "auth",
                        // save the location we were at to come back later
                        query: { redirect: to.fullPath },
                    };
                }
            }
        } else if ((to.meta.requiresAuth || to.meta.roles) && !isLoggedIn) {
            // 2. If the requires auth via requiresAuth meta, check if user is logged in
            // if not, redirect to login page.
            notyf.error({
                message:
                    "Sorry, you should loggin to access this section (anything will work)",
                duration: 7000,
            });

            return {
                // Will follow the redirection set in /@src/pages/auth/index.vue
                name: "auth",
                // save the location we were at to come back later
                query: { redirect: to.fullPath },
            };
        }
    });
}
